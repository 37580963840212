

.popup .ant-popover-content {
}

.popup .ant-popover-content .ant-popover-inner {
  background-color: #305FED;
  box-shadow: none;
  border-radius: 6px;
}

.popup .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  padding: 10px;
}

.popup .ant-popover-arrow {
  top: 3px;
  right: 6px;
}

.popup .ant-popover-arrow-content {
  box-shadow: none;
  background-color: #305FED;
}


.ant-dropdown-menu > .general {
  display: none;
}

.ant-popover {
  max-width: 94%;
}

@media screen and (max-width: 500px) {
  .ant-dropdown-menu > .general {
    display: block;
  }
}
