
@font-face {
  font-family: Poppins;
  src: url('../font/Poppins-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: Poppins;
  src: url('../font/Poppins-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: Poppins;
  src: url('../font/Poppins-Light.ttf');
  font-weight: lighter;
}

body {
  font-family: 'Poppins';
  background-color: #F6F7FB;
  font-size: 16px;
  margin: 0px;
}

.editor-view {
  top: 0px !important;
  height: 100vh;
  margin: 0px;
  max-width: 100%;
  z-index: 10;
}

.editor-view > .ant-modal-content {
  background-color: transparent;
}

.editor-view > .ant-modal-content > .ant-modal-body {
  padding: 0px;
}

.full-dialog-view {
  top: 0px !important;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  max-width: 100%;
  z-index: 10;
}

.full-dialog-view > .ant-modal-content {
  background-color: transparent;
}

.full-dialog-view > .ant-modal-content > .ant-modal-body {
  padding: 0px;
}

@page {
  size: portrait;
  width: 210mm;
  height: 297mm;
  margin: 0px;
  margin-bottom: 10mm;

  background-color: white;
}

#widthie {
  display: none;
}
