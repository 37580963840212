

.drawer-content > .ant-drawer-content-wrapper {
  background-color: transparent;
  box-shadow: none !important;
  justify-content: center;
}

.drawer-content > .ant-drawer-content-wrapper > .ant-drawer-content {
  border-radius: 24px 24px 0px 0px;
  max-width: 800px;
}

.drawer-floorplan > .ant-drawer-content-wrapper {
  background-color: transparent;
  box-shadow: none !important;
}

.drawer-floorplan > .ant-drawer-content-wrapper > .ant-drawer-content {
  border-radius: 12px 12px 0px 0px;
  max-width: 800px;
  margin: 0 auto;
}

.drawer-floorplan > .ant-drawer-content-wrapper > .ant-drawer-content > .ant-drawer-wrapper-body > .ant-drawer-body {
  padding: 12px;
}
